@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  color: #0d69c0;
  text-decoration: none;
}

a:hover {
  color: #09569b;
}

ul,
li {
  list-style: none;
}

.error {
  color: #f44336;
}

/* get rid of autofill jank */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  -webkit-text-fill-color: black !important;
}

:focus {
  outline: none;
}

/*******CSS CLASSES FOR HIDING AND SHOWING ITEMS BASED ON SCREEN SIZE******/

/*this is to make the footer stick to the bottom*/
#app,
#app > div {
  height: 100%;
}

/* ebook styles */
/*special font that is used in our font dropdown*/
@font-face {
  font-family: 'dyslexic';
  src: url('../fonts/OpenDyslexicAlta-Regular.otf');
}

_::-webkit-full-page-media,
_:future,
:root .safari_only,
.show-focus [tabindex]:not([tabindex='-1']):focus {
  border: 1px solid transparent;
}

/* All focusable elements should have a pronounced border */
.show-focus a[href]:not([tabindex='-1']):focus,
.show-focus area[href]:not([tabindex='-1']):focus,
.show-focus select:not([disabled]):not([tabindex='-1']):focus,
.show-focus textarea:not([disabled]):not([tabindex='-1']):focus,
.show-focus input:not([disabled]):not([tabindex='-1']):focus,
.show-focus button:not([disabled]):not([tabindex='-1']):focus,
.show-focus iframe:not([tabindex='-1']):focus,
.show-focus [tabindex]:not([tabindex='-1']):focus,
.show-focus [contentEditable='true']:not([tabindex='-1']):focus {
  /*
   * Adds an inner white border and an outer blue border around the button.
   * The white and blue have a color contrast ratio of at least 3:1, which
   * means this will work against any background color.
   */
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px dodgerblue;

  /*
   * Since box-shadow is invisible in Windows high-contrast mode, we need to
   * add a transparent outline (which does show up in high-contrast mode)
   */
  outline: 2px solid transparent;
  outline-offset: 2px;
}

/***** OVERRIDES FOR THE TABBED PANELS ******/
/* DO NOT DELETE UNTIL WE REMOVE REACT RESPONSIVE TABS */
.RRT__tab {
  border-radius: 3px !important;
  border: 0 !important;
  margin: 0 5px 5px 0 !important;
  -webkit-transition: 0.05s ease-in-out !important;
  -moz-transition: 0.05s ease-in-out !important;
  -o-transition: 0.05s ease-in-out !important;
  transition: 0.05s ease-in-out !important;
}

.RRT__tab:hover,
.RRT__tab:focus,
.RRT__tab--selected {
  background: #2f6da7 !important;
  color: #fff !important;
}

/* Removes 'eye-visibility' icons from input fields that reveal passwords
  first is for edge and the second is for FF/Safari, they cannot be combined because of Safari
*/
#kidsModePin::-ms-reveal,
#kidsModePin::-ms-clear {
  display: none;
}
#kidsModePin::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  right: 0;
}

/* This is here because tailwind doesn't have height-based media queries */
@media (max-height: 450px) {
  .hootie-popover-panel {
    z-index: 100;
    width: 100% !important;
    min-height: auto !important;
    height: 100% !important;
    right: 0 !important;
    bottom: 0 !important;
  }
}
